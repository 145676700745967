<template>
  <div class="container">
    <My-Breadcrumb :breadcrumbs="breadcrumbs" />
    <div class="mainBox">
      <div class="control_box">
        <Search-bar :inputs="inputs" @search="search" @reset="search" />
        <div class="control_box_bottom">
          <a-button type="primary" icon="plus" @click="showBatch()" style="margin-right: 10px">添加幻灯片</a-button>
        </div>
      </div>
      <!-- 内容区域 -->
      <div class="show_box">
        <a-table :columns="columns" :data-source="tableData" :loading="loading" :rowKey="(tableData) => tableData.id"
          :pagination="pagination" @change="handleTableChange">
          <template slot="action" slot-scope="record">
            <a-button type="link" @click="() => edit(record.id)">编辑</a-button>
            <a-divider type="vertical" />
            <a-button type="link" @click="() => remove(record.id)">删除</a-button>
          </template>
          <template slot="is_valid" slot-scope="record">
            <a-switch :checked="record.is_valid == 1" :loading="disableLoading" @change="() => changeStatus(record)" />
          </template>
        </a-table>
      </div>
    </div>
    <!-- 新增/修改 -->
    <a-modal v-model="editModalVisible" :title="editModalTitle" :okText="editModalOkText" cancelText="取消" :width="700"
      @ok="preSave()">
      <a-form-model ref="batchForm" :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }" :model="tmp"
        :rules="formRules">
        <a-form-model-item label="图片">
          <div>
            <img v-if="tmp.content" :src="tmp.content" alt srcset />
          </div>
          <a-upload name="link_url" :multiple="false" :action="uploadUrl" :headers="headers"
            @change="handleUploadChange" :showUploadList="false" :beforeUpload="beforeUpload">
            <a-button>
              <a-icon type="upload" /> 上传图片（300kb以内）
            </a-button>
          </a-upload>

        </a-form-model-item>
        <a-form-model-item label="标题" prop="title">
          <a-input v-model="tmp.title" />
        </a-form-model-item>
        <a-form-model-item label="排序" prop="order_weight">
          <a-input v-model="tmp.order_weight" />
        </a-form-model-item>
        <a-form-model-item label="状态">
          <a-switch :checked="tmp.is_valid == 1" @change="() => changeTmpStatus()" />
        </a-form-model-item>

      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
//引入接口api
import {
  ads,
  disableAd,
  addAd,
  editAd,
  getEditAd,
  removeAd,
  swiperUploadApi
} from "@/request/api";
import {
  baseUrl
} from '@/request/http'
//引入封装的组件
import SearchBar from "@/components/common/SearchBar.vue";
import MyBreadcrumb from "@/components/common/MyBreadcrumb.vue";
//引入工具函数
import util from "@/utils/util";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    SearchBar,
    MyBreadcrumb,
  },
  data () {
    return {
      //传给MyBreadcrumb的参数
      breadcrumbs: [
        {
          key: 0,
          title: "系统设置",
        },
        {
          key: 1,
          title: "首页幻灯片管理",
        },
      ],

      //传给searchBar公共搜索组件的数据
      inputs: [
        {
          placeholder: "请输入幻灯片名称",
          model: "title",
          width: 300,
        },
      ],
      //请求参数
      queryParam: {
        page: 1,
        title: "",
      },
      tableData: [], //从接口请求回来并在js部分整理好的表格数据
      dataForExport: [], //不分页的数据
      loading: false, //table加载状态

      // 表格的列配置
      columns: [
        {
          title: "标题",
          key: "title",
          dataIndex: "title",
        },
        {
          title: "排序权重",
          key: "order_weight",
          dataIndex: "order_weight",
        },
        {
          title: "创建时间",
          key: "created_at",
          dataIndex: "created_at",
        },
        {
          title: "状态",
          key: "is_valid",
          scopedSlots: { customRender: "is_valid" },
        },
        {
          title: "操作",
          key: "action",
          scopedSlots: { customRender: "action" },
        },
      ],

      //页配置
      pagination: {
        total: 0,
        pageSize: 15,
        current: 1,
      },
      disableLoading: false,
      editModalVisible: false,
      editModalTitle: "新增幻灯片",
      editModalOkText: '保存',
      tmp: {
        id: "",
        title: "",
        content: "",
        order_weight: "",
        is_valid: 1,
      },
      uploadUrl:baseUrl+swiperUploadApi,
      headers: {
        Authorization: "Bearer" + " " + localStorage.getItem("token"),
      },
      formRules: {
        title: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        order_weight: [
          { required: true, message: "请输入排序权重", trigger: "blur" },
          { pattern: /^[0-9]+$/, message: "排序权重格式错误", trigger: "blur" },
        ],
      },
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    //刷新和搜索一样的方法
    search (val) {
      this.queryParam = {
        page: 1,
        title: val.input.title ? val.input.title : "",
      };
      this.pagination.current = 1;
      this.getTableData();
    },

    //获取表格数据
    getTableData () {
      this.loading = true;
      this.queryParam.no_paging = 0;
      ads(this.queryParam).then((res) => {
        //处理页码
        const pagination = { ...this.pagination };
        pagination.total = res.data.total;
        this.pagination = pagination;
        if (res.data.data.length == 0) {
          this.loading = false;
          this.tableData = [];
          return;
        }
        this.tableData = res.data.data;
        this.loading = false;
      });
    },
    edit (id) {
      getEditAd(id)
        .then((res) => {
          this.tmp = res.data;
          this.editModalVisible = true;
          this.editModalTitle = "修改幻灯片";
          this.editModalOkText = '提交修改'
        })
        .catch((err) => {
          let { errors } = err.data;
          for (let i in errors) {
            this.$message.error(errors[i][0]);
          }
        });
    },
    changeStatus (row) {
      this.disableLoading = true;
      let id = row.id;
      let p = {
        is_valid: row.is_valid == 1 ? 0 : 1,
      };
      disableAd(id, p)
        .then((res) => {})
        .catch((err) => {
          this.disableLoading = false;
          if (err.status == 201) {
            this.$message.success("操作成功");
            row.is_valid = p.is_valid;
          } else {
            let { errors } = err.data;
            for (let i in errors) {
              this.$message.error(errors[i][0]);
            }
          }
        });
    },
    changeTmpStatus () {
      this.tmp.is_valid = this.tmp.is_valid == 1 ? 0 : 1;
    },
    //分页
    handleTableChange (pagination) {
      this.pagination.current = pagination.current;
      this.pagination.pageSize = pagination.pageSize;
      this.queryParam.page = pagination.current;
      this.getTableData();
    },

    //格式转换
    formatJson (filterVal, jsonData) {
      return jsonData.map((v) => filterVal.map((j) => v[j]));
    },

    //打开个性化批量充值导入模态框
    showBatch () {
      this.editModalVisible = true;
      this.editModalTitle = "添加幻灯片";
      this.editModalOkText = '保存'
      this.resetTmp();
    },
    // 上传文件之前的钩子
    beforeUpload (file) {
      let self = this;
      return new Promise(function (resolve, reject) {
        let filereader = new FileReader();
        filereader.onload = e => {
          let src = e.target.result;
          const image = new Image();
          image.onload = function () {
            // 获取图片的宽高，并存放到file对象中
            file.width = this.width;
            file.height = this.height;
            resolve();
          };
          image.onerror = reject;
          image.src = src;
        };
        filereader.readAsDataURL(file);
      });
    },
    handleUploadChange (res) {
      let file = res.file;
      if ((file.status == "done" || file.status == "error") && file.size > (1024 * 300)) {
        this.$message.info('请上传300kb以下的图片')
        return
      }
      // if(file.status == "done" && (file.width/file.height != 16/9)){
      //   this.$message.info('图片宽高比需为16比9')
      //   return
      // }
      if (!!res.file.response) {
        this.tmp.content = res.file.response.path;
      }
    },
    preSave () {
      let th = this;
      this.$refs["batchForm"].validate((valid) => {
        if (valid) {
          th.saveAd();
        }
      });
    },
    saveAd () {
      this.tmp.disable = this.tmp.is_valid;
      this.tmp.sort = this.tmp.order_weight;
      if (!this.tmp.id) {
        addAd(this.tmp)
          .then((res) => {})
          .catch((err) => {
            if (err.status == 201) {
              this.$message.success("操作成功");
              this.getTableData();
              this.editModalVisible = false;
            } else {
              let { errors } = err.data;
              for (let i in errors) {
                this.$message.error(errors[i][0]);
              }
            }
          });
      } else {
        editAd(this.tmp.id, this.tmp)
          .then((res) => {})
          .catch((err) => {
            if (err.status == 201) {
              this.$message.success("操作成功");
              this.getTableData();
              this.editModalVisible = false;
            } else {
              let { errors } = err.data;
              for (let i in errors) {
                this.$message.error(errors[i][0]);
              }
            }
          });
      }
    },
    remove: function (id) {
      let th = this;
      this.$confirm({
        title: "操作提示",
        content: "删除之后不可恢复，您确认删除该数据？",
        onOk () {
          removeAd(id)
            .then((res) => {})
            .catch((err) => {
              if (err.status == 201) {
                th.$message.success("操作成功");
                th.getTableData();
              } else {
                let { errors } = err.data;
                for (let i in errors) {
                  th.$message.error(errors[i][0]);
                }
              }
            });
        },
        onCancel () { },
      });
    },
    resetTmp () {
      this.tmp.id = "";
      this.tmp.title = "";
      this.tmp.content = "";
      this.tmp.order_weight = "";
      this.tmp.is_valid = 1;
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created () {
    this.getTableData();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () { },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.mainBox {
  background-color: #f0f2f5;
  .control_box {
    background-color: white;
    padding: 20px;
    .control_box_bottom {
      margin-top: 20px;
    }
  }
  .show_box {
    width: 100%;
    padding-top: 10px;
  }
}
.e-space {
  margin-left: 10px;
}
img {
  width: 260px;
}
</style>